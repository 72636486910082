<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="opinionItems"
            type="search"
            itemText="follwUpExamStandardOpinionName"
            itemValue="follwUpExamStandardOpinion"
            name="follwUpExamStandardOpinion"
            label="소견"
            v-model="searchParam.follwUpExamStandardOpinion"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="추적검사관리 기준 목록"
      :merge="grid.merge"
      :columns="grid.columns"
      :data="grid.data"
      :editable="editable"
      @table-data-change="tableDataChange"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn v-if="editable" label="추가" icon="add" @btnClicked="add" />
          <c-btn
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="grid.data"
            mappingType="PUT"
            label="저장"
            icon="save"
            @beforeAction="save"
            @btnCallback="saveCallback" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'follwUpExamStandardOpinion'">
          <c-text-column
            :editable="editable"
            :col="col"
            :props="props"
            @datachange="datachange(props, col)"
          />
          <div v-if="editable">
            <q-btn-group outline class="ColumInnerBtnGroup">
              <q-btn
                icon="add"
                color="red-6"
                text-color="white"
                class="ColumInnerBtn"
                align="center"
                @click.prevent="innerBtnClicked(col, props)">
                <q-tooltip>
                  소견별 분류 추가
                </q-tooltip>
              </q-btn>
            </q-btn-group>
          </div>
        </template>
      </template>
    </c-table>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: "follwUpExamStandard",
    data() {
    return {
      searchParam: {
        follwUpExamStandardOpinion: null,
        useFlag: 'Y'
      },
      grid: {
        merge: [
          { index: 0, colName: 'follwUpExamStandardOpinion', },
        ],
        columns: [
          {
            name: 'follwUpExamStandardOpinion',
            field: 'follwUpExamStandardOpinion',
            label: '소견',
            align: 'left',
            sortable: true,
            required: true,
            style: 'width: 250px',
            type: 'custom'
          },
          {
            name: 'follwUpExamStandardClass',
            field: 'follwUpExamStandardClass',
            label: '분류',
            align: 'left',
            sortable: true,
            required: true,
            style: 'width: 250px',
            type: 'text'
          },
          {
            name: 'unit',
            field: 'unit',
            label: '단위',
            align: 'center',
            sortable: true,
            style: 'width: 150px',
            type: 'text'
          },
          {
            name: 'good',
            field: 'good',
            label: '양호',
            child: [
              {
                name: 'goodFlag',
                field: 'goodFlag',
                label: ' 적용여부',
                align: 'center',
                sortable: true,
                style: 'width:80px',
                type: 'check',
                true: 'Y',
                false: 'N',
              },
              {
                name: 'goodFrom',
                field: 'goodFrom',
                label: 'From',
                align: 'right',
                sortable: true,
                style: 'width: 100px',
                type: 'number',
                disableTarget: 'goodFlag',
                disableCon: 'Y',
              },
              {
                name: 'goodTo',
                field: 'goodTo',
                label: 'To',
                align: 'right',
                sortable: true,
                style: 'width: 100px',
                type: 'number',
                disableTarget: 'goodFlag',
                disableCon: 'Y',
              },
            ]
          },
          {
            name: 'caution',
            field: 'caution',
            label: '주의',
            child: [
              {
                name: 'cautionFlag',
                field: 'cautionFlag',
                label: ' 적용여부',
                align: 'center',
                sortable: true,
                style: 'width:80px',
                type: 'check',
                true: 'Y',
                false: 'N',
              },
              {
                name: 'cautionFrom',
                field: 'cautionFrom',
                label: 'From',
                align: 'right',
                sortable: true,
                style: 'width: 100px',
                type: 'number',
                disableTarget: 'cautionFlag',
                disableCon: 'Y',
              },
              {
                name: 'cautionTo',
                field: 'cautionTo',
                label: 'To',
                align: 'right',
                sortable: true,
                style: 'width: 100px',
                type: 'number',
                disableTarget: 'cautionFlag',
                disableCon: 'Y',
              },
            ]
          },
          {
            name: 'danger',
            field: 'danger',
            label: '위험',
            child: [
              {
                name: 'dangerFlag',
                field: 'dangerFlag',
                label: ' 적용여부',
                align: 'center',
                sortable: true,
                style: 'width:80px',
                type: 'check',
                true: 'Y',
                false: 'N',
              },
              {
                name: 'dangerFrom',
                field: 'dangerFrom',
                label: 'From',
                align: 'right',
                sortable: true,
                style: 'width: 100px',
                type: 'number',
                disableTarget: 'dangerFlag',
                disableCon: 'Y',
              },
              {
                name: 'dangerTo',
                field: 'dangerTo',
                label: 'To',
                align: 'right',
                sortable: true,
                style: 'width: 100px',
                type: 'number',
                disableTarget: 'dangerFlag',
                disableCon: 'Y',
              },
            ]
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            sortable: true,
            style: 'width:100px',
            type: 'check',
            true: 'Y',
            false: 'N',
          },
        ],
        data: [],
      },
      opinionItems: [],
      useFlagItems: [],
      editable: true,
      isSave: false,
      listUrl: '',
      validUrl: '',
      opinionListUrl: '',
      saveUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.hea.fue.standard.list.url
      this.validUrl = selectConfig.hea.fue.standard.valid.url
      this.opinionListUrl = selectConfig.hea.fue.standard.opinion.list.url
      this.saveUrl = transactionConfig.hea.fue.standard.save.url
      // code setting
      this.getOpinions();
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    getOpinions() {
      this.$http.url = this.opinionListUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.opinionItems = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    tableDataChange(row, col) {
      if (col.name === 'goodFlag' && row[col.name] === 'N') {
        this.$set(row, 'goodFrom', '')
        this.$set(row, 'goodTo', '')
      } else if (col.name === 'cautionFlag' && row[col.name] === 'N') {
        this.$set(row, 'cautionFrom', '')
        this.$set(row, 'cautionTo', '')
      } else if (col.name === 'dangerFlag' && row[col.name] === 'N') {
        this.$set(row, 'dangerFrom', '')
        this.$set(row, 'dangerTo', '')
      }
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    innerBtnClicked(col, props) {
      this.grid.data.splice(props.rowIndex, 0, {
        heaFollwUpExamStandardId: uid(),  // 추적검사관리 기준 일련번호
        follwUpExamStandardOpinion: props.row.follwUpExamStandardOpinion,  // 소견
        follwUpExamStandardClass: '',  // 소견 분류
        unit: '',  // 단위
        goodFlag: 'Y',  // 양호 사용여부
        goodFrom: '',  // 양호 - from
        goodTo: '',  // 양호 - to
        cautionFlag: 'Y',  // 경고 사용여부
        cautionFrom: '',  // 주의 - from
        cautionTo: '',  // 주의 - to
        dangerFlag: 'Y',  // 위험 사용여부
        dangerFrom: '',  // 위험 - from
        dangerTo: '',  // 위험 - to
        useFlag: 'Y',  // 사용여부
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C'
      })
    },
    add() {
      if (!this.grid.data) this.grid.data = [];
      this.grid.data.splice(0, 0, {
        heaFollwUpExamStandardId: uid(),  // 추적검사관리 기준 일련번호
        follwUpExamStandardOpinion: '',  // 소견
        follwUpExamStandardClass: '',  // 소견 분류
        unit: '',  // 단위
        goodFlag: 'Y',  // 양호 사용여부
        goodFrom: '',  // 양호 - from
        goodTo: '',  // 양호 - to
        cautionFlag: 'Y',  // 경고 사용여부
        cautionFrom: '',  // 주의 - from
        cautionTo: '',  // 주의 - to
        dangerFlag: 'Y',  // 위험 사용여부
        dangerFrom: '',  // 위험 - from
        dangerTo: '',  // 위험 - to
        useFlag: 'Y',  // 사용여부
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C'
      })
    },
    save() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        this.$http.url = this.validUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          valids: this.$_.filter(this.grid.data, item => {
            return item.editFlag === 'C' || item.editFlag === 'U'
          })
        }
        this.$http.request((_result) => {
          if (_result.data === 0) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '저장하시겠습니까?',
              
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {            
                this.isSave = !this.isSave
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('ALERT', {
              title: '안내 ', 
              message: '입력하신 소견 / 분류가 이미 존재합니다.\n다시 기입바랍니다.',
              type: 'success', // success / info / warning / error
            });
          }
        },);
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getOpinions();
      this.getList();
    },
  },
};
</script>
